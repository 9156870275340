.settings-block {
  padding: 1.75rem;
  border: 1px solid #616161;
  position: relative;

  @include media-breakpoint-up(lg) {
    min-height: 100%;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 1.25rem;
  }
  @include media-breakpoint-down(sm) {
    padding: 1.25rem;
  }

  &:not(:first-of-type) {
    @include media-breakpoint-up(lg) {
      margin-left: -1px;
    }
  }

  &__label {
    font-weight: 300;
    font-size: 1.3125rem;
    padding-left: 1em;

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
      font-weight: 400;
    }

    &:before, &:after {
      @include media-breakpoint-up(md) {
        top: 0;
      }
    }

    &_badge {
      padding-right: 1.625rem;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 0.6875rem;
    color: rgba($white, .5);
    margin: 1.5rem 0 0 3.5rem;

    @include media-breakpoint-down(sm) {
      margin: 1rem 0 0 3rem;
    }
  }

  &__badge {
    position: absolute;
    top: 1.75rem;
    right: 1.75rem;
    border: 1px solid rgba($white, 0.5);
    font-size: 0.625rem;
    line-height: 1.625rem;
    width: 1.625rem;
    height: 1.625rem;
    display: flex;
    justify-content: center;
    font-weight: 600;

    @include media-breakpoint-down(sm) {
      top: 1.25rem;
      right: 1.25rem;
    }
  }
}