.app-start-container {
  margin-left: auto;
  margin-right: auto;

  .app-start-content {
    padding-top: 3rem;
    text-align: center;
    letter-spacing: 0.02em;
    line-height: 1.19em;

    @include media-breakpoint-down(lg) {
      padding-top: 2rem;
    }
  }

  .no-device {
    height: 270px;
    background: url($image_path + '/no-device.svg') center center transparent no-repeat;
    margin: auto;
    background-size: contain;

    @include media-breakpoint-down(sm) {
      height: 100px;
    }
  }

  .app-start-title {
    font-weight: 300;
    font-size: 2.125rem;
    margin: 4.75rem auto 1.5rem;
    letter-spacing: 0.014em;
    line-height: 1.177;
    color: $primary;
    max-width: 15em;

    @include media-breakpoint-down(md) {
      margin: 3.5rem auto 1.5rem;
    }

    @include media-breakpoint-down(sm) {
      margin: 2.5rem auto 1rem;
      font-size: 1.75rem;
    }
  }

  .add-device-link {
    max-width: 300px;
    margin: 2.25rem auto 3.5rem;
    text-transform: uppercase;
    position: relative;
    padding-left: 60px;

    .add-device-link-icon {
      $width: 20px;
      $border: 2px;
      position: absolute;
      left: 2.5rem;
      top: calc(50% - #{$border / 2});

      &:before {
        position: absolute;
        content: "";
        width: $width;
        height: 2px;
        background-color: $white;
        top: 0;
        left: 50%;
        margin-left: -1 * $width / 2;
      }
      &:after {
        position: absolute;
        content: "";
        width: $border;
        height: $width;
        top: -1 * $width / 2 + $border / 2;
        left: 50%;
        margin-left: -1 * $border / 2;
        background-color: $white;
      }
    }
  }

}
