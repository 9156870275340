.profile-page {
    &__user-data {
        @include media-breakpoint-up(md) {
            background: str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='#{$primary}' d='M55.475 23.579c0-13.022-10.51-23.579-23.475-23.579s-23.475 10.557-23.475 23.579c0 8.126 4.093 15.292 10.32 19.532-8.95 3.837-15.901 11.472-18.846 20.889h7.122c3.98-9.875 13.619-16.842 24.878-16.842s20.898 6.966 24.878 16.842h7.122c-2.944-9.417-9.895-17.051-18.846-20.889 6.228-4.24 10.32-11.406 10.32-19.532zM48.768 23.579c0 9.302-7.507 16.842-16.768 16.842s-16.768-7.54-16.768-16.842 7.507-16.842 16.768-16.842c9.26 0 16.768 7.54 16.768 16.842z'/%3e%3c/svg%3e"), "#", "%23") no-repeat right top transparent;
            background-size: 178px;
        }
    }

    &__user-data-row {
        display: flex;
        border-bottom: 1px solid rgba($white, .2);
        padding: 2rem 0;
        align-items: center;

        @include media-breakpoint-up(lg) {
            padding-right: 3rem;
        }
    }

    &__user-data-key {
        font-size: .75rem;
        font-weight: 300;
        width: 40%;
    }

    &__user-data-value {
        font-size: 1rem;
        font-weight: 600;
        flex-grow: 1;

        &--phone {
            input {
                text-indent: 0 !important;
            }
        }
    }

    &__btn {
        text-transform: uppercase;

        &_change-password {
            text-transform: none;
            color: $primary;
            text-decoration: underline;
        }

        &_delete-account {
            text-transform: none;
            color: $danger;
            text-decoration: underline;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            display: block;
            margin-bottom: 2rem;
        }
    }
}