.app-uikit {
  .icon-list {
    .icon-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      font-size: 1rem;
      color: $white;
      margin-bottom: $grid-gutter-width / 2;
      border: 1px solid rgba($white, .2);
      position: relative;
      padding-bottom: 1rem;

      .icon-name {
        position: absolute;
        bottom: .25rem;
        width: 100%;
        text-align: center;
      }

      &:hover {
        font-size: 2em;
        color: $primary;
        border-color: $primary;
        cursor: pointer;

        .icon-name {
          font-size: 1rem;
        }
      }
    }
  }
}