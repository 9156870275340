@import "variables";

.MuiSlider-root {
  $margin-y: $slider-thumb-width / 2;
  color: $primary;
  margin-left: $margin-y;
  margin-right: $margin-y;
  width: calc(100% - #{$margin-y * 2}) !important;

  .MuiSlider-rail {
    height: 5px;
    background-color: rgba($white, .2);

    &:before {
      content: '';
      position: absolute;
      right: calc(#{-1 * $slider-thumb-width / 2} + 1px);
      top: 0;
      width: calc(#{$slider-thumb-width / 2} - 1px);
      height: 100%;
      background-color: inherit;
    }
  }
  .MuiSlider-track {
    height: 5px;
    background-color: $primary;

    &:before {
      content: '';
      position: absolute;
      left: calc(#{-1 * $slider-thumb-width / 2} + 1px);
      top: 0;
      width: calc(#{$slider-thumb-width / 2} - 1px);
      height: 100%;
      background-color: inherit;
    }
  }
  .MuiSlider-mark {
    height: 7px;
    background-color: $body-bg;

    &[data-index="0"] {
      display: none;
    }
    &[data-index="10"] {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  .MuiSlider-markActive {
    opacity: 1;
    background-color: $body-bg;
  }
  .MuiSlider-markLabel {
    color: $white;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  .MuiSlider-markLabelActive {
    color: $white;
  }
  .MuiSlider-markLabel {
    top: 2.5rem;
  }
  .MuiSlider-thumb {
    width: $slider-thumb-width;
    height: $slider-thumb-width;
    margin-top: -1 * $slider-thumb-width / 2;
    margin-left: -1 * $slider-thumb-width / 2;
    background-color: $body-bg;
    border: 6px solid $primary;

    .MuiSlider-valueLabel {
      left: calc(-50% + 0.75rem);
      font-size: 1.5rem;
      color: $primary;
      background-color: transparent;

      * {
        color: $primary;
        background-color: transparent;
      }
    }
  }
}