.app-signup-container {

  .app-signup-title {
    font-weight: 300;
    font-size: 1rem;
    margin-bottom: 2rem;
    letter-spacing: 0.02em;
    line-height: 1.37;
    text-align: center;
  }

  input {
    text-align: center;
  }

  .invalid-feedback {
    text-align: center;
  }

  .link {
    color: $primary;
    text-decoration: underline;

    &:hover, &:active {
      color: darken($primary, 10%);
      text-decoration: none;
    }
  }

  .custom-control-label {
    letter-spacing: 0.02em;
    line-height: 1.37;
  }

  .app-signin-link {
    margin: 1.5rem auto 1rem;
    text-align: center;
    display: block;
    padding: 1rem 0;
  }

}
