.card {
  .card-title {
    color: $white;
    font-weight: 300;
    font-size: 2.125rem;
    line-height: 0.85em;
    letter-spacing: 0.015em;

    @include media-breakpoint-down(md) {
      font-size: 1.5rem;
    }
  }

  .card-body {
    @include media-breakpoint-down(md) {
      padding: 1rem;
    }
  }

}