@import "variables";
@import "mixins";

.report-block {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid $primary;
  height: 100%;

  @each $breakpoint, $spacing-y in $report-block-spacing-y {
    @include media-breakpoint-up($breakpoint) {
      padding: $spacing-y;
    }
  }

  &_lg {
    @each $breakpoint, $spacing-y in $report-block-spacing-lg-y {
      @include media-breakpoint-up($breakpoint) {
        padding: $spacing-y;
      }
    }
  }

  &__hr {
    display: none !important;

    @include media-breakpoint-down(md) {
      display: block !important;
      border-top: 1px solid #6E757B;
      margin: 1rem 0;
    }
  }

  &_household-consumption {
    border-color: $white;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;

    & .report-block__chart {
      margin-bottom: 0;
    }

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
    
      &:nth-child(2) {
        // flex: 1;
        @include media-breakpoint-down(sm) {
          flex-direction: column;

          & > div:first-child {
            align-self: flex-start;
          }
        }
      }

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    @include media-breakpoint-down(md) {
      flex-direction: column!important;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: stretch;

    @include media-breakpoint-down(md) {
      & .report-block {
        &__part:nth-child(2) {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
  &__bottom-titles {
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  @include media-breakpoint-down(lg) {
    &__energy {
      margin-bottom: 0.5rem;
    }
  }

  &__chart {
    margin-bottom: 1.5rem;
    @include media-breakpoint-down(lg) {
      margin-bottom: 1rem;
    }
  }

  &__title {
    font-size: 1rem;
    line-height: 1.17;
    letter-spacing: 0.02em;
    flex-grow: 1;
    color: $primary;

    @include media-breakpoint-down(lg) {
      font-size: 1.1rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 1rem;
      padding-right: 2rem;
    }

    &_lg {
      font-weight: 300;
      font-size: 2rem;
      padding-top: 0;
      flex-grow: unset;

      @include media-breakpoint-down(lg) {
        font-size: 1.5rem;
      }
      @include media-breakpoint-down(md) {
        font-size: 1rem;
      }
    }
  }

  &__description {
    font-size: 0.6rem;
    line-height: 1.62;
    letter-spacing: 0.03em;
    color: $white;
    margin-top: .25rem;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      height: auto;
      overflow: inherit;
    }

    &_lg {
      font-size: 0.75rem;
    }

    &_household-consumption {
      padding-right: 0;
      height: auto;
      margin: 0;
    }
  }

  &__energy {
    font-weight: 400;
    font-size: 1.8rem;
    letter-spacing: 0.01em;
    color: $primary;
    margin-top: .5rem;
    display: flex;
    align-items: baseline;

    @include media-breakpoint-down(xl) {
      font-size: 2.25rem;
    }
    @include media-breakpoint-down(lg) {
      margin-left: 0.5rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 1.6rem;
    }

    &_lg {
      font-size: 4rem;
      flex-grow: 0;

      @include media-breakpoint-down(lg) {
        font-size: 3.5rem;
      }
      @include media-breakpoint-only(md) {
        font-size: 2rem;
      }
      @include media-breakpoint-down(sm) {
        font-size: 1.6rem;
      }
    }

    &_household-consumption {
      padding: 0;

      @include media-breakpoint-up(lg) {
        margin-left: 3rem !important;
      }
    }
  }

  &__energy-unit {
    font-weight: 300;
    font-size: 1.25rem;
    letter-spacing: 0.02em;
    color: $primary;
    display: inline-block;
    margin-left: .5rem;

    @include media-breakpoint-down(lg) {
      font-size: 1.15rem;
    }

    @include media-breakpoint-down(md) {
      font-size: .875rem;
    }
  }

  &__icon {
    font-size: 2.5rem;
    margin-top: 0.25rem;
    margin-right: 1rem;

    @include media-breakpoint-down(lg) {
      font-size: 2.15rem;
      margin-right: 0.5rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }

    &_lg {
      font-size: 4rem;
      
      @include media-breakpoint-down(lg) {
        font-size: 3rem;
      }
      @include media-breakpoint-down(md) {
        font-size: 2rem;
      }
    }

    &_household-consumption {
      font-size: 4rem;
      position: static;

      @include media-breakpoint-down(md) {
        font-size: 2rem;
        margin: 0 .5rem;
      }
      @include media-breakpoint-up(lg) {
        margin: 0 2rem;
      }
    }
  }
}