#app-site {
  height: auto;
  min-height: 100vh;
}

.app-main, .app-container {
  width: 100%;
  min-height: 100vh;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.app-content {
  flex-grow: 1;
}

.app-page-title {
  color: $primary;
  font-weight: 300;
  font-size: 2.75rem;
  line-height: 1.2;
  letter-spacing: 0.011rem;
  margin-top: 3rem;
  margin-bottom: 3rem;

  @include media-breakpoint-down(md) {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  @include media-breakpoint-down(sm) {
    font-size: 1.75rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}