.intl-tel-input {
  width: 100%;

  input {
    text-indent: -52px;
  }

  .country-list {
    background-color: $black;

    .country {
      &:hover {
        background-color: rgba($primary, .5);
      }
    }
  }
}