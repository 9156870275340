.display-width-alert {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba($red, .75);
  padding: 1rem;
  text-align: center;
  font-size: $font-size-lg;

  @include media-breakpoint-between(md, xl) {
    display: block;
  }
}