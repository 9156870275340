.form-control {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: white !important;
    -webkit-box-shadow: 0 0 0 30px black inset !important;
    -webkit-animation: autofill 0s forwards;
    animation: autofill 0s forwards;
  }

  &:disabled,
  &[readonly] {
    opacity: .5;
  }

  &::placeholder {
    font-weight: $input-placeholder-weight;
  }
}

@keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
    font-size: inherit;
  }
}