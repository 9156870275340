.page-faq {
  &__container {
    height: calc(100vh - 400px);
    min-height: 400px;
    display: flex;
    font-weight: 300;
    width: 100%;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &__question-list {
    width: 450px;
    min-width: 450px;
    max-height: 100%;
    overflow-y: auto;

    @include media-breakpoint-down(lg) {
      width: 300px;
      min-width: 300px;
    }

    @include media-breakpoint-down(md) {
      width: 250px;
      min-width: 250px;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &_select {
      display: none;

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
  }

  &__question-item {
    border: 1px solid #6E757B;
    padding: 1.75rem 1.75rem 1.75rem 5.375rem;
    position: relative;
    font-size: 1.3rem;
    line-height: 1.57;
    cursor: pointer;

    &:hover {
      border-color: $primary;
      color: $primary;
    }

    &_active {
      background-color: $primary;
      border-color: $primary;

      &:hover {
        color: $white;
      }
    }

    @include media-breakpoint-down(md) {
      padding: 1.25rem 1.25rem 1.25rem 2.75rem;
      font-size: 1rem;
    }
  }

  &__question-item-icon {
    position: absolute;
    top: 1.75rem;
    left: 2.25rem;

    @include media-breakpoint-down(md) {
      top: 1.25rem;
      left: 1rem;
    }
  }

  &__answer {
    height: 100%;
    border: 1px solid #6E757B;
    margin-right: -1px;
    padding: 3rem;
    position: relative;
    font-size: 1.5rem;
    line-height: 1.6;
    flex-grow: 1;
    overflow-y: auto;
    width: 100%;

    @include media-breakpoint-down(md) {
      padding: 2rem;
      font-size: 1.25rem;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 1rem;
    }
  }

  &__answer-stub {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.37;
    flex-direction: column;
    text-align: center;
  }

  &__answer-stub-icon {
    font-size: 8.75rem;
    margin-bottom: 4.5rem;

    @include media-breakpoint-down(lg) {
      font-size: 5rem;
      margin-bottom: 3.5rem;
    }

    @include media-breakpoint-down(md) {
      font-size: 4rem;
      margin-bottom: 3rem;
    }
  }

  &__search {
    position: relative;
    width: 320px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-top: 1rem;
    }
  }

  &__search-control {
    width: 100%;
  }

  &__search-button {
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
    width: 47px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0 none;
    background-color: transparent;
    color: $white;
    cursor: pointer;
    font-size: 1rem;
  }
}