.user-notification {
  padding: 0;
  width: 420px;

  @include media-breakpoint-down(sm) {
    width: auto;
  }

  &__unread-total {
    border-bottom: 1px solid rgba($black, .15);
    font-size: .875rem;
    text-transform: uppercase;
    padding: 1.5rem 1.5rem 1.5rem;
    position: relative;

    &_full {
      background-color: rgba($blue, .15);
    }
  }

  &__clear-btn {
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
    font-size: 1.25rem;
    color: #616161;
    cursor: pointer;
  }

  &__list {
    height: 420px;
    max-height: 420px;
    overflow-y: scroll;

    &_empty {
      height: 200px;
      max-height: 200px;
    }
  }

  &__list-stub {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 300;
  }

  &__item {
    padding: 1.5rem 1.5rem 1.5rem;
    position: relative;
    display: flex;
    align-items: center;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 1.5rem;
      width: calc(100% - 3rem);
      height: 1px;
      border-bottom: 1px solid rgba($black, .15);
    }

    &:last-of-type {
      &:after {
        content: none;
      }
    }

    &_unread {
      background-color: rgba($blue, .15);
    }
  }

  &__icon {
    width: 1.8125rem;
    height: 1.8125rem;
    background-color: $electric;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.625rem;
    color: $primary;
    margin-right: 1rem;
    flex-shrink: 0;
    position: relative;

    &:before {
      content: "";
      left: 0;
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $primary;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 1rem;
      mask-size: 1rem;
      -webkit-mask-position: center center;
      mask-position: center center;
    }

    &_success-tick {
      background-color: $primary;

      &:before {
        background-color: $white;
        -webkit-mask-image: url($icon_path + '/check.svg');
        mask-image: url($icon_path + '/check.svg');
      }
    }

    &_warning {
      background-color: $red;

      &:before {
        background-color: $white;
        -webkit-mask-size: .25rem;
        mask-size: .25rem;
        -webkit-mask-image: url($icon_path + '/exclamation.svg');
        mask-image: url($icon_path + '/exclamation.svg');
      }
    }

    &_info {
      &:before {
        -webkit-mask-size: .25rem;
        mask-size: .25rem;
        -webkit-mask-image: url($icon_path + '/exclamation.svg');
        mask-image: url($icon_path + '/exclamation.svg');
      }
    }

    &_cloud-ok {
      &:before {
        -webkit-mask-size: 1.25rem;
        mask-size: 1.25rem;
        -webkit-mask-image: url($icon_path + '/cloud.svg');
        mask-image: url($icon_path + '/cloud.svg');
      }
    }

    &_cloud-fail {
      &:before {
        -webkit-mask-size: 1.25rem;
        mask-size: 1.25rem;
        -webkit-mask-image: url($icon_path + '/cloud.svg');
        mask-image: url($icon_path + '/cloud.svg');
      }
    }

    &_plug {
      &:before {
        -webkit-mask-size: 1.25rem;
        mask-size: 1.25rem;
        -webkit-mask-image: url($icon_path + '/socket.svg');
        mask-image: url($icon_path + '/socket.svg');
      }
    }

    &_file {
      &:before {
        -webkit-mask-image: url($icon_path + '/file-4g.svg');
        mask-image: url($icon_path + '/file-4g.svg');
      }
    }

    &_wifi {
      &:before {
        -webkit-mask-size: 1.25rem;
        mask-size: 1.25rem;
        -webkit-mask-image: url($icon_path + '/wifi.svg');
        mask-image: url($icon_path + '/wifi.svg');
      }
    }

    &_bell {
      &:before {
        -webkit-mask-image: url($icon_path + '/bell.svg');
        mask-image: url($icon_path + '/bell.svg');
      }
    }
  }

  &__text {
    font-size: 0.875rem;
    flex-grow: 1;
  }

  &__created {
    color: rgba($black, .5);
    font-size: 0.625rem;
    margin-left: 1rem;
    flex-shrink: 0;
  }
}