.device-offline-modal {
  &__footer {
    justify-content: center;
  }

  &__description {
    font-weight: 300;
  }

  &__image {
    background: url($image_path + '/device-offline.svg') center center transparent no-repeat;
    height: 180px;
    margin-top: 2rem;

    &_vpp {
      background-image: url($image_path + '/device-vpp.svg');
    }
  }

  &__refresh-screen-btn {
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
      display: block;
      width: 100%;
    }
  }
}