.cookies-clause {
    & .energizer-brand-name {
      font-style: "italic";
      @media (prefers-color-scheme: light) {
        color: "#000";
      }
    }

    &, & * {
      font-family: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    & a {
      text-decoration: underline;
    }

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 60px;
    max-height: 400px;
    color: #fff;
    z-index: 9900;
    background-color: #202020;
    border-top: 1px solid #fff;
    font-family: Nunito,sans-serif;
    transition: bottom .3s cubic-bezier(.17,.04,.03,.94);

    @media (prefers-color-scheme: light) {
      background-color: #fff;
      border-top: 1px solid #202020;
      color: #202020;
    }

    & .container {
      padding: 10px 0;

      @media (max-width: 767px) {
        padding: 15px;
      }
    }

    &__content {
      padding-left: 30px;
      padding-right: 30px;
      text-align: left;
      display: flex;
      align-items: center;
      width: 100%;

      @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
        display: block;
        min-height: auto;
      }
    }

    &__notice {
      @media (max-width: 767px) {
        padding-left: 4px;
        margin-bottom: 10px;
      }

      & p {
        margin: 0;
        font-size: 14px;
        line-height: 18px;
        padding-bottom: 0;
      }
    }

    &__buttons {
      padding-left: 3vw;
      display: flex;
      align-items: center;
    }

    &__button {
      color: #000;
      text-transform: uppercase;

      font-size: 14px;
      line-height: 20px;
      text-decoration: none;

      padding: 8px 30px;
      transition: all .3s ease;
      border: none;
      display: inline-block;
      margin: 3px 4px;
      white-space: nowrap;
      letter-spacing: 0;
      cursor: pointer;

      @media (prefers-color-scheme: dark) {
        color: "#fff";
      }

      &_primary {
        color: #fff;

        &:hover {
          background-color: #000;
        }
      }

      &_reject {
        margin-left: 3rem;
        border: 3px solid transparent;
        padding-left: 0;
        padding-right: 0;

        &:hover {
          border-bottom-color: #000;
          color: #000;
        }
      }
    }
}