@import "variables";

.locale-select {
  position: relative;

  &__dropdown-toggle {
    text-align: left;
  }

  &__item {
    text-align: left;
  }

  &--styled {
    &.show {
      .locale-select__dropdown-toggle {
        visibility: hidden;
      }
    }

    .locale-select__dropdown-toggle {
      color: $white;
      padding: 10px 12px;
      width: 100%;
      background-color: transparent;
      border: 1px solid $white;

      &:hover {
        border-color: $primary;
      }

      &:active {
        border-color: $primary !important;
        background-color: transparent !important;
      }

      &:after {
        position: absolute;
        top: calc(50% - .15em);
        right: 8px;
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
      }

      .locale-select__flag {
        top: 2px !important;
      }
    }

    .locale-select__dropdown-menu {
      transform: none !important;
      box-shadow: none;
      border-radius: 0;
      border: 1px solid $primary;
      width: 100%;
      padding: 1px 8px;
      background: rgba($black, .8);
      margin: 0;
      color: $white;
      top: -1px !important;
    }

    .locale-select__dropdown-menu-item {
      text-align: left;
      color: $white !important;
      padding: 10px 4px !important;
      background-color: transparent !important;

      &:hover {
        background-color: rgba($primary, .5) !important;
      }
    }
  }

  &__flag {
    position: relative;
    left: 0;
    top: 3px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    width: 20px;
    height: 16px;
    margin-right: 10px;
    display: inline-block;

    @each $code in $locale-select-locales {
      &--#{$code} {
        background-image: url($icon_path + '/flags/' + $code + '.svg');
      }
    }
    @each $code in $locale-select-locale-border {
      &--#{$code} {
        border: 1px solid gray;
      }
    }
  }
}
