.app-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  .navbar {

    .navbar-brand {
      margin-right: 4.5rem;

      @include media-breakpoint-down(lg) {
        margin-right: 2.5rem;
      }
      @include media-breakpoint-down(md) {
        margin-right: 1rem;
      }
      @include media-breakpoint-down(sm) {
        margin-right: 0;
      }
    }

    .nav-link {
      font-weight: 600;
      font-size: $nav-link-font-size;
      line-height: $nav-link-font-line-height;
      text-transform: uppercase;
      position: relative;

      @include media-breakpoint-only(lg) {
        padding-left: 3rem;
        padding-right: 2rem;
      }

      @include media-breakpoint-only(md) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }

      @include media-breakpoint-down(sm) {
        padding: 1.25rem 1rem 1.25rem 3rem;
      }

      > .icon {
        position: absolute;
        left: .75rem;
        font-size: 1.125rem;
        top: calc(50% - #{1.125rem / 2});

        @include media-breakpoint-only(md) {
          position: static;
        }

        &.icon-report {
          font-size: .875rem;
          top: calc(50% - #{0.875rem / 2});
        }
      }

      > .nav-text {
        @include media-breakpoint-only(md) {
          display: none;
        }
      }

      &.active {
        > .icon {
          color: $primary;
        }
      }

      @include media-breakpoint-up(lg) {
        &.active, &:hover, &:focus {
          &:after {
            content: '';
            border-bottom: 1px solid $primary;
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
          }
        }
      }
    }
  }

  .dropdown {
    .nav-link-dropdown {
      &:before {
        content: '';
        background: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='none' stroke-linejoin='miter' stroke-linecap='butt' stroke-miterlimit='4' stroke-width='7.1111' stroke='white' d='M2.889 9.949l13.111 12.103 13.111-12.103'/%3e%3c/svg%3e") no-repeat center center;
        position: absolute;
        right: .75rem;
        top: calc(50% - #{0.5625rem / 2});
        width: 0.5625rem;
        height: 0.5625rem;
        transition: transform .5s ease-in 0s;
      }
    }

    &.show {
      .nav-link-dropdown {
        &:before {
          transform: rotateX(180deg);
          background-image: str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='none' stroke-linejoin='miter' stroke-linecap='butt' stroke-miterlimit='4' stroke-width='7.1111' stroke='#{$primary}' d='M2.889 9.949l13.111 12.103 13.111-12.103'/%3e%3c/svg%3e"), "#", "%23") !important;
        }
      }
    }

    .dropdown-item {
      position: relative;
      font-weight: 300;

      > .icon {
        position: absolute;
        left: 1.5rem;
        font-size: 1.125rem;
        top: calc(50% - #{1.125rem / 2});
      }

      &.active {
        font-weight: 600;

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          width: .25rem;
          height: 100%;
          background-color: $primary;
          content: "";
        }

        > .icon {
          color: $primary;
        }
      }

      &:hover, &:focus {
        background-color: rgba($primary, .5);

        > .icon {
          color: $black;
        }
      }
    }
  }

  .menu-user {
    .nav-item {

      .nav-link {
        @include media-breakpoint-up(md) {
          font-weight: 400;
          text-transform: none;
        }

        > .icon {
          @include media-breakpoint-up(md) {
            font-size: 1.5rem;
            top: calc(50% - #{1.5rem / 2});
          }
        }
      }

      .dropdown {
        &.show {
          @include media-breakpoint-up(md) {
            background-color: $white;
          }

          .nav-link {
            @include media-breakpoint-up(md) {
              color: $black;
            }
          }

          .nav-link-dropdown {
            &:before {
              @include media-breakpoint-up(md) {
                top: calc(50% - #{0.815rem / 2});
                width: 0.815rem;
                height: 0.815rem;
              }
            }
          }
        }
      }

      &.device-select {
        $width: 240px;

        .nav-link {
          @include media-breakpoint-up(md) {
            width: $width;
            text-align: center;
          }

          @include media-breakpoint-between(md, lg) {
            width: auto;
          }

          .device-serial {
            @include media-breakpoint-up(xl) {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: calc(#{$width} - #{$nav-link-padding-x * 2});
            }

            @include media-breakpoint-between(md, lg) {
              display: none;
            }
          }

          .placeholder {
            display: none;
          }
        }

        &.show {
          background-color: $electric;
          border: 0 none;

          .nav-link {
            color: $black;

            @include media-breakpoint-up(md) {
              $font-size: 0.875rem;
              $padding-y: ($nav-link-height - $font-size * $nav-link-font-line-height) / 2;
              font-size: $font-size;
              padding-top: $padding-y;
              padding-bottom: $padding-y;
              font-weight: 400;
            }

            .device-serial {
              display: none;
            }

            .placeholder {
              display: inline-block;

              @include media-breakpoint-between(md, lg) {
                display: none;
              }
            }
          }

          .dropdown-menu {
            background-color: $electric;
            padding-bottom: 0;
          }

          .dropdown-item {
            background-color: $electric;
            position: relative;

            &.device-select-item {
              $activeStateWidth: 1.375rem;
              font-size: 0.875rem;

              &:before {
                content: '';
                position: absolute;

                width: $activeStateWidth;
                height: $activeStateWidth;
                border-radius: 50%;
                border: 1px solid $primary;
                background-color: $white;
                left: 1.8125rem;
                top: calc(50% - #{$activeStateWidth / 2});
              }

              &.active {
                &:before {
                  background-color: $primary;
                }

                &:after {
                  $iconSize: 0.5625rem;
                  content: '';
                  position: absolute;
                  left: 1.8125rem;
                  top: calc(50% - #{$activeStateWidth / 2});
                  width: $activeStateWidth;
                  height: $activeStateWidth;
                  background: str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='#{$white}' d='M54.264 2.777l-33.517 41.899-13.81-9.214-6.851 10.276 23.241 15.486 40.584-50.727-9.649-7.719z'/%3e%3c/svg%3e"), "#", "%23") center center no-repeat transparent;
                  background-size: $iconSize;
                }
              }
            }

            &.link-battery {
              color: $white;
              background-color: $primary;
              margin-top: 1rem;
              padding-top: 1.6rem;
              padding-bottom: 1.6rem;
              text-transform: uppercase;

              .icon {
                left: 1.6rem;
                font-size: 1.5rem;
                top: calc(50% - #{1.5rem / 2});
              }

              &:hover, &:focus, &:active {
                .icon {
                  color: $white;
                }
              }
            }
          }
        }

        .nav-link-dropdown {
          &:before {
            @include media-breakpoint-up(md) {
              background-image: str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='none' stroke-linejoin='miter' stroke-linecap='butt' stroke-miterlimit='4' stroke-width='7.1111' stroke='#{$white}' d='M2.889 9.949l13.111 12.103 13.111-12.103'/%3e%3c/svg%3e"), "#", "%23");
            }
          }
        }
      }

      &.notifications {
        &.show {
          .nav-link {
            @include media-breakpoint-up(md) {
              color: $black;
            }
          }

          @include media-breakpoint-up(md) {
            background-color: $white;
          }

          &.unread {
            @include media-breakpoint-up(md) {
              background-color: #E3F3F7;
            }
          }
        }

        .unread-count {
          font-size: .5rem;
          color: $white;
          line-height: 1.2;
          font-weight: 600;
          background-color: $red;
          padding: 1px 7px;
          position: absolute;
          border-radius: .5rem;
          top: 1rem;
          right: 1rem;

          @include media-breakpoint-down(sm) {
            right: auto;
            left: 1.5rem;
          }
        }
      }

      &.profile {
        .dropdown-menu {
          padding-bottom: 0;
        }

        .nav-link {
          .profile-close {
            display: none;
          }
        }

        &.show {
          .nav-link {
            .profile-open {
              display: none;
            }
            .profile-close {
              display: block;
            }

            @include media-breakpoint-up(md) {
              color: $black;
            }
          }

          @include media-breakpoint-up(md) {
            background-color: $white;
          }
        }

        .link-battery {
          color: $primary;
          line-height: 2rem;
          font-size: .875rem;

          &:hover, &:focus, &:active {
            color: $white;
            background-color: $primary;

            .icon {
              color: $white;
            }
          }
        }

        .dropdown-header {
          font-size: 1.0625rem;
          line-height: 1.25;
          font-weight: 600;
          color: $black;
          position: relative;
          margin-bottom: .5rem;

          .icon {
            position: absolute;
            font-size: 1.875rem;
            color: $primary;
            left: 1.1rem;
            top: calc(50% - #{1.875rem / 2});
          }

          .logout {
            .icon {
              font-size: 1.375rem;
              color: $primary;
              right: 1.1rem;
              left: auto;
              top: calc(50% - #{1.375rem / 2});
            }
          }
        }
      }

      @include media-breakpoint-up(md) {
        border-left: 1px solid rgba(255, 255, 255, 0.2);
      }

      &.notifications, &.profile {
        .nav-link {
          @include media-breakpoint-up(md) {
            height: 100%;
          }

          .icon {
            @include media-breakpoint-up(md) {
              left: calc(50% - #{1.5rem / 2} - 2px);
              top: calc(50% - #{1.5rem / 2});
            }
          }

          .nav-text {
            @include media-breakpoint-up(md) {
              display: none;
            }
          }
        }
      }
    }
  }
}