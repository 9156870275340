.page-settings-application-mode {
  &__schedule-title {
    margin: 0;

    @include media-breakpoint-down(md) {
      cursor: pointer;
      position: relative;
    }

    &:after {
      @include media-breakpoint-down(md) {
        transition: transform 0.25s ease-in;
        content: '';
        position: absolute;
        right: 0;
        width: 1rem;
        height: 100%;
        background-position: right center;
        background-repeat: no-repeat;
        background-color: transparent;
        background-image: str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='none' stroke-linejoin='miter' stroke-linecap='butt' stroke-miterlimit='4' stroke-width='7.1111' stroke='#{$primary}' d='M2.889 9.949l13.111 12.103 13.111-12.103'/%3e%3c/svg%3e"), "#", "%23") !important;
      }
    }

    &_collapsed {
      &:after {
        @include media-breakpoint-down(md) {
          transform: rotateZ(180deg);
        }
      }
    }
  }

  &__schedule-detail {
    @include media-breakpoint-down(md) {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.25s ease-in;
    }

    &_collapsed {
      @include media-breakpoint-down(md) {
        max-height: 2000px;
      }
    }
  }

  &__form-control-button_clock {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__schedule-active-label {
    @include media-breakpoint-down(lg) {
      position: absolute;
      right: -1.8rem;
    }
  }

  &__schedule-group {
    &:not(:last-of-type) {
      @include media-breakpoint-down(sm) {
        border-bottom: 1px dashed rgba($white, 0.2);
        margin-bottom: 1rem;
      }
    }
  }

  &__form-group-label {
    @include media-breakpoint-down(sm) {
      font-size: 1.25rem;
    }
  }

  .rc-time-picker-input,
  .page-settings__form-control,
  .page-settings__form-control-button {
    &[disabled] {
      opacity: 1;
    }
  }

  &__schedule-disabled {
    opacity: .5;

    .page-settings__form-control-suffix {
      z-index: 1;
    }
  }
}