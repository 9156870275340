@import "variables";
.dashboard-page {

  $box-margin-right: nth($report-grid-components-spacing, 2);
  $box-margin-bottom: nth($report-grid-components-spacing, 1);

  .report-grid {
    &__container {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto 1fr 1fr;
      grid-template-areas:
        "pv-generation pv-generation-components household-consumption"
        "grid-usage grid-usage-components household-consumption"
        "battery-usage battery-usage-components household-consumption";

      @each $breakpoint, $gap in $report-grid-gaps {
        @include media-breakpoint-up($breakpoint) {
          gap: nth($gap, 1) nth($gap, 2);
        }
      }

      @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(7, auto);
        grid-template-areas:
          "household-consumption"
          "pv-generation"
          "pv-generation-components"
          "grid-usage"
          "grid-usage-components"
          "battery-usage"
          "battery-usage-components"
        ;
      }
      @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(7, auto);
        grid-template-areas:
          "household-consumption"
          "pv-generation"
          "pv-generation-components"
          "grid-usage"
          "grid-usage-components"
          "battery-usage"
          "battery-usage-components"
        ;
      }

      &_pv-generation-components {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
        grid-template-areas: "charged-battery" "pv-into-house" "pv-export-to-grid";

        @include media-breakpoint-only(md) {
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: 1fr;
          grid-template-areas: "charged-battery pv-export-to-grid pv-into-house";
        }
        @include media-breakpoint-down(sm) {
          margin-left: 3rem;
          grid-template-areas: "charged-battery" "pv-export-to-grid" "pv-into-house";
        }

        &:before {
          content: '';
          position: absolute;
          top: -1 * nth(map_get($report-grid-gaps, xs), 1);
          left: calc(-1.5rem - 1px);
          width: 1px;
          height: calc(100% - (33% / 2 - #{nth(map_get($report-grid-gaps, xs), 1)}) + 5px);
          border: 1px solid $primary;

          @include media-breakpoint-only(md) {
            top:  calc(#{nth(map_get($report-grid-gaps, md), 2)} / 3 * 2 * -1 - 1px);
            left: calc(33% / 2 - .5rem);
            width: calc(100% - (33% - #{2 * nth(map_get($report-grid-gaps, lg), 1)} / 3) - 2px);
            height: 1px;
          }

          @include media-breakpoint-up(lg) {
            top: calc(33% / 2 - #{nth(map_get($report-grid-gaps, lg), 1)} / 3);
            left: -1 * nth(map_get($report-grid-gaps, lg), 2) / 3;
            height: calc(100% - (33% - #{2 * nth(map_get($report-grid-gaps, lg), 1)} / 3) - 2px);
          }
          @include media-breakpoint-up(xl) {
            top: calc(33% / 2 - #{nth(map_get($report-grid-gaps, xl), 1)} / 3 + 2px);
            left: -1 * nth(map_get($report-grid-gaps, xl), 2) / 3;
            height: calc(100% - (33% - #{2 * nth(map_get($report-grid-gaps, xl), 1)} / 3) - 2px);
          }
        }
      }

      &_grid-usage-components {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas: "grid-into-house" "grid-to-battery";

        @include media-breakpoint-only(md) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr;
          grid-template-areas: "grid-to-battery grid-into-house";
        }
        @include media-breakpoint-down(sm) {
          margin-left: 3rem;
          grid-template-areas: "grid-to-battery" "grid-into-house";
        }

        &:before {
          content: '';
          position: absolute;
          top: -1 * nth(map_get($report-grid-gaps, xs), 1);
          left: calc(-1.5rem - 1px);
          width: 1px;
          height: calc(100% - (50% / 2 - #{nth(map_get($report-grid-gaps, xs), 1)}) + 5px);
          border: 1px solid $primary;

          @include media-breakpoint-only(md) {
            top:  calc(#{nth(map_get($report-grid-gaps, md), 2)} / 3 * 2 * -1 - 1px);
            left: calc(50% / 2 - .5rem + 1px);
            width: calc(100% - (50% - #{2 * nth(map_get($report-grid-gaps, lg), 1)} / 3) - 2px);
            height: 1px;
          }

          @include media-breakpoint-up(lg) {
            top: calc(50% / 2 - #{nth(map_get($report-grid-gaps, lg), 1)} / 3 + 1px);
            left: -1 * nth(map_get($report-grid-gaps, lg), 2) / 3;
            height: calc(100% - (50% - #{2 * nth(map_get($report-grid-gaps, lg), 1)} / 3) - 3px);
          }
          @include media-breakpoint-up(xl) {
            top: calc(50% / 2 - #{nth(map_get($report-grid-gaps, xl), 1)} / 3 + 2px);
            left: -1 * nth(map_get($report-grid-gaps, xl), 2) / 3;
            height: calc(100% - (50% - #{2 * nth(map_get($report-grid-gaps, xl), 1)} / 3) - 4px);
          }
        }
      }

      &_battery-usage-components {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas: "battery-to-house" "battery-to-grid";

        @include media-breakpoint-only(md) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr;
          grid-template-areas: "battery-to-grid battery-to-house";
        }
        @include media-breakpoint-down(sm) {
          margin-left: 3rem;
          grid-template-areas: "battery-to-grid" "battery-to-house";
        }

        &:before {
          content: '';
          position: absolute;
          top: -1 * nth(map_get($report-grid-gaps, xs), 1);
          left: calc(-1.5rem - 1px);
          width: 1px;
          height: calc(100% - (50% / 2 - #{nth(map_get($report-grid-gaps, xs), 1)}) + 5px);
          border: 1px solid $primary;

          @include media-breakpoint-only(md) {
            top:  calc(#{nth(map_get($report-grid-gaps, md), 2)} / 3 * 2 * -1 - 1px);
            left: calc(50% / 2 - .5rem + 1px);
            width: calc(100% - (50% - #{2 * nth(map_get($report-grid-gaps, lg), 1)} / 3) - 2px);
            height: 1px;
          }

          @include media-breakpoint-up(lg) {
            top: calc(50% / 2 - #{nth(map_get($report-grid-gaps, lg), 1)} / 3 + 1px);
            left: -1 * nth(map_get($report-grid-gaps, lg), 2) / 3;
            height: calc(100% - (50% - #{2 * nth(map_get($report-grid-gaps, lg), 1)} / 3) - 4px);
          }
          @include media-breakpoint-up(xl) {
            top: calc(50% / 2 - #{nth(map_get($report-grid-gaps, xl), 1)} / 3 + 3px);
            left: -1 * nth(map_get($report-grid-gaps, xl), 2) / 3;
            height: calc(100% - (50% - #{2 * nth(map_get($report-grid-gaps, xl), 1)} / 3) - 5px);
          }
        }
      }
    }

    &__box {
      &_components {
        @include media-breakpoint-only(md) {
          margin-right: 3rem;
        }
      }

      &_household-consumption {
        @include media-breakpoint-up(lg) {
          margin-top: 115%;
          margin-bottom: 115%;
        }
        @include media-breakpoint-up(xl) {
          margin-top: 107%;
          margin-bottom: 107%;
        }
        @include media-breakpoint-up(xxl) {
          margin-top: 90%;
          margin-bottom: 90%;
        }
      }

      &_battery-usage,
      &_pv-generation,
      &_grid-usage {
        @include media-breakpoint-only(md) {
          margin-right: 3rem;
        }
      }
    }
  }

  .report-block {
      &_lg {
        font-size: 2rem;

        @include media-breakpoint-down(lg) {
          font-size: 1.75rem;
        }
        @include media-breakpoint-down(md) {
          font-size: 1.5rem;
          padding: 0;
        }

        br {
          display: none !important;
        }
      }

      &_big-chart {
        margin-top: 7rem;

        @include media-breakpoint-down(lg) {
          margin-top: 5rem;
        }
        @include media-breakpoint-down(md) {
          margin-top: 0;
        }

        br {
          display: inherit !important;
        }
      }

      &_household-consumption {
        @include media-breakpoint-only(md) {
          margin-left: 0;
          margin-top: 2rem;
        }
      }

      &_self-sufficiency {
        @include media-breakpoint-down(md) {
          margin: 0 auto 1rem;
        }
      }

    &__description {
      &_lg {
        margin-top: 0.5rem;
        flex-grow: 1;

        @include media-breakpoint-down(md) {
          margin-top: 0;
        }
      }

      &_household-consumption {
        flex-grow: 0;
      }
    }

    &__icon {
      font-size: 2rem;

      @include media-breakpoint-down(lg) {
        font-size: 2.5rem;
      }
      @include media-breakpoint-down(md) {
        font-size: 1.5rem;
      }

      &_lg {
        font-size: 4.125rem;
        position: relative;
        display: block;

        @include media-breakpoint-down(lg) {
          font-size: 3rem;
        }
        @include media-breakpoint-down(md) {
          font-size: 2rem;
        }
      }

      &_big-chart {
        font-size: 7.5rem;

        @include media-breakpoint-down(lg) {
          font-size: 4rem;
        }
        @include media-breakpoint-down(md) {
          font-size: 2.5rem;
        }
      }

      &_household-consumption {
        font-size: 4rem;

        @include media-breakpoint-only(md) {
          font-size: 2.5rem;
        }

        @include media-breakpoint-down(md) {
          font-size: 2.5rem;
        }
      }
    }

    &__chart {
      &_household-consumption {
        flex-grow: 0;

        @include media-breakpoint-down(md) {
          position: static;
          margin: auto;
        }
        @include media-breakpoint-up(md) {
          padding-right: 2rem;
        }
      }
    }

    &__energy {
      margin-left: 0.5rem;
    }

    &_household-consumption {
      border-color: $white;
      flex-direction: row;

      @include media-breakpoint-only(md) {
        flex-direction: row;
      }
      @include media-breakpoint-down(sm) {
        text-align: left;
      }
    }

    &__part {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &_flex-1 {
        flex: 1;
      }
    }

    &_to-household-consumption {
      @include media-breakpoint-down(md) {
        border-color: $white;
      }
    }

    &_pv-into-house {
      &:before {
        @include media-breakpoint-up(lg) {
          top: 2rem;
        }
        @include media-breakpoint-up(xl) {
          top: 3rem;
        }
      }
      &:after {
        @include media-breakpoint-up(lg) {
          top: 2rem;
          right: calc(-50% - #{nth(map_get($report-grid-gaps, lg), 2)} - 3px);
          width: calc(50% + #{nth(map_get($report-grid-gaps, lg), 2)} + 3px);
        }
        @include media-breakpoint-up(xl) {
          top: 3rem;
          right: calc(-50% - #{nth(map_get($report-grid-gaps, xl), 2)} - 1px);
          width: calc(50% + #{nth(map_get($report-grid-gaps, xl), 2)} + 1px);
        }
      }
    }

    &_grid-into-house {
      &:after {
        @include media-breakpoint-up(lg) {
          $width: nth(map_get($report-grid-gaps, lg), 2);
          right: -1 * $width;
          width: $width;
        }
        @include media-breakpoint-up(xl) {
          $width: nth(map_get($report-grid-gaps, xl), 2);
          right: -1 * $width;
          width: $width;
        }
      }
    }

    &_battery-to-house {
      &:before {
        @include media-breakpoint-up(lg) {
          top: auto;
          bottom: 2rem;
          margin-top: 0;
          margin-bottom: -1 * map_get($report-block-dot-sizes, lg) / 2;
        }
        @include media-breakpoint-up(xl) {
          bottom: 3rem;
        }
      }
      &:after {
        @include media-breakpoint-up(lg) {
          top: auto;
          bottom: 2rem;
          right: calc(-50% - #{nth(map_get($report-grid-gaps, lg), 2)} - 3px);
          width: calc(50% + #{nth(map_get($report-grid-gaps, lg), 2)} + 3px);
        }
        @include media-breakpoint-up(xl) {
          bottom: 3rem;
          right: calc(-50% - #{nth(map_get($report-grid-gaps, xl), 2)} - 1px);
          width: calc(50% + #{nth(map_get($report-grid-gaps, xl), 2)} + 1px);
        }
      }
    }

    &__hr {
      &_household-consumption {
        @include media-breakpoint-only(md) {
          width: 1px;
          height: 100%;
          border-left: 1px solid rgba($white, 0.2);
        }
      }
    }
  }
}