.dateselect {
  font-size: 2rem;
  line-height: 1.2;
  letter-spacing: 0.00875em;
  color: $white;
  display: flex;
  align-items: center;
  margin-top: 1rem;

  @include media-breakpoint-down(md) {
    font-size: 1.5rem;
    margin-top: 0;
  }

  @include media-breakpoint-down(sm) {
    font-size: 1.1rem;
  }

  .dateselect-calendar {
    color: $primary;
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    background: transparent;
    border: 0 none;

    &:focus {
      outline: 0;
    }

    .icon-calendar {
      color: $primary;
    }
  }

  .arrow-down {
    width: 2rem;
    height: 1rem;
    background: str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='none' stroke-linejoin='miter' stroke-linecap='butt' stroke-miterlimit='4' stroke-width='7.1111' stroke='#{$primary}' d='M2.889 9.949l13.111 12.103 13.111-12.103'/%3e%3c/svg%3e"), "#", "%23") no-repeat center center;
    display: inline-block;
  }

  .selected-date {
    margin: 0 2rem;

    @include media-breakpoint-down(sm) {
      margin: 0 1rem;
      flex-grow: 1;
      text-align: center;
    }
  }

  .date-prev-btn, .date-next-btn {
    border: 1px solid rgba(255, 255, 255, 0.2);
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    background-size: 50%;
    cursor: pointer;
    margin: 0;
    padding: 0;
    width: 2.8125rem;
    height: 2.8125rem;

    &:hover {
      border-color: $white;
    }

    &_disabled {
      cursor: default;
      opacity: .5;
      border-color: rgba(255, 255, 255, 0.4);

      &:hover {
        border-color: rgba(255, 255, 255, 0.4);
      }
    }
  }
  .date-prev-btn {
    background-image: str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='none' stroke-linejoin='miter' stroke-linecap='butt' stroke-miterlimit='4' stroke-width='2.7826' stroke='#{$white}' d='M22.286 30.667l-12.571-14.667 12.571-14.667'/%3e%3c/svg%3e"), "#", "%23");
  }
  .date-next-btn {
    margin-left: .5rem;
    background-image: str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='none' stroke-linejoin='miter' stroke-linecap='butt' stroke-miterlimit='4' stroke-width='2.7826' stroke='#{$white}' d='M9.714 30.667l12.571-14.667-12.571-14.667'/%3e%3c/svg%3e"), "#", "%23");
  }
}