@import "variables";

.report-flex {
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }

    @each $breakpoint, $gap in $report-grid-gaps {
      @include media-breakpoint-up($breakpoint) {
        gap: $gap;
      }
    }
  }

  &__box {
    &-w100 {
      grid-column: span 3;

      @include media-breakpoint-down(md) {
        grid-column: span 2;
      }
      @include media-breakpoint-down(sm) {
        grid-column: span 1;
      }
    }

    &_hide-md-down {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}

// deprecated
.report-grid {
  &__container {
    display: grid;

    @each $breakpoint, $gap in $report-grid-gaps {
      @include media-breakpoint-up($breakpoint) {
        gap: $gap;
      }
    }
  }

  &__box {
    position: relative;

    @each $box in $report-grid-boxes {
      &_#{$box} {
        grid-area: #{$box};
      }
    }

    &_components {
      display: grid;
      @each $breakpoint, $gap in $report-grid-gaps {
        @include media-breakpoint-up($breakpoint) {
          gap: nth($gap, 1);
        }
      }
    }
  }
}