@import '~rc-time-picker/assets/index.css';

.rc-time-picker {
  padding: 0;
}

.rc-time-picker-panel {
  padding-top: 3rem;
}

.rc-time-picker-input {
  background-color: $body-bg;
  height: 100%;
  border: 0 none;
  padding: $input-padding-y $input-padding-x;
  color: $white;
  font-size: 1rem;
  font-weight: 600;

  &[disabled] {
    background-color: $body-bg;
    opacity: .5;
  }
}

.rc-time-picker-panel-input-wrap {
  display: none;
}

.rc-time-picker-panel-combobox {
  color: $black;
}