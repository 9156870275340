.icon {
  display: inline-block;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;

  &.icon-fw {
    text-align: center;
    width: (20em / 16);
  }
}