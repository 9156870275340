.app-footer {
  font-size: 0.75rem;
  position: relative;
  margin-top: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  line-height: 162%;
  letter-spacing: 0.02em;

  @include media-breakpoint-down(md) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &:before {
    content: '';
    background: rgba(255, 255, 255, 0.2);
    height: 1px;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  @include media-breakpoint-down(md) {
    margin-top: 2rem;
  }

  .copyright {
    margin-bottom: 1rem;
  }

  .disclaimer {
    opacity: .5;
    font-size: .625rem;
    max-width: 1000px;

    @include media-breakpoint-down(lg) {
      max-width: 800px;
    }
  }
}