.weather {
  $width: 150px;
  $width-sm: 80px;

  @include media-breakpoint-down(sm) {
    border: 0 none;
  }

  .card-body {
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }

  .weather-container {
    display: flex;
    justify-content: space-around;

    @include media-breakpoint-down(sm) {
      $count: 6;
      width: $width-sm * $count + 8px * ($count - 1);
    }
  }

  .weather-item {
    text-align: center;
    font-size: 1.125rem;
    line-height: 2.22em;
    letter-spacing: 0.03em;
    width: $width;
    border: 1px solid transparent;

    &:hover {
      border-color: $white;
    }

    @include media-breakpoint-down(sm) {
      border: 1px solid rgba($white, .2);
      width: $width-sm;
    }
  }

  .weather-hour {
    font-weight: 300;
  }

  .weather-icon {
    height: 30px;
    width: 30px;
    margin: auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: contain;

    $icons: (
      '01d', '01n',
      '02d', '02n',
      '03d', '03n',
      '04d', '04n',
      '09d', '09n',
      '10d', '10n',
      '11d', '11n',
      '13d', '13n',
      '50d', '50n',
    );

    @each $code in $icons {
      &.w#{$code} {
        background-image: url($image_path + '/weather-icons/' + $code + '.svg');
      }
    }
  }

  .weather-temperature {
    font-weight: 600;
  }
}