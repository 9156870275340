.app-recovery {
  .form-control-recovery-code {
    max-width: 3rem;
    text-align: center;
  }

  .app-recovery-success {
    font-weight: 300;
    font-size: 1.5rem;
  }

  .app-recovery-success-smile {
    font-size: 5rem;
    color: $primary;
  }
}