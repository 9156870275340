$bg-blue: #006AFF;
$grey: #606770;

.interval-range-modal {
  .modal-content {
    background-color: #fff;

    .modal-header {
      border-bottom: 1px solid #006AFF;

      .modal-title {
        padding: 0;
        color: $grey;
        text-align: left;
        font-size: 1rem;
      }

      .close {
        &::before, &::after {
          background-color: #000;
        }
      }
    }

    .modal-footer {
      justify-content: space-between;

      & .btn {
        @include media-breakpoint-down(md) {
          padding: 1.125rem 2rem;
          flex: 1;
        }
      }
    }
  }

  &__input {
    background-color: transparent;
    color: $grey;
    font-weight: normal;
    text-align: left;

    &:focus {
      background-color: transparent;
      color: $grey;
      border: 1px solid #006AFF;
    }
  }
}

.daterangepicker {
  .ranges {
    color: $bg-blue;

    @include media-breakpoint-down(md) {
      display: none;
    }

    & > ul {
      display: flex;
      flex-direction: column;
      gap: .25rem;
      padding: .25rem .5rem;

      & > li {
        background-color: #f5f5f5;
        border-radius: 3px;

        &.active {
          background-color: $bg-blue !important;
          color: #fff;
        }
      }
    }
  }

  &.show-calendar .ranges {
    margin-top: 0 !important;
  }

  .drp-calendar {
    display: block !important;
  }

  .calendar-table {
    & th {
      color: $grey;
    }

    & td {
      color: $grey;

      &.active {
        background-color: $bg-blue;
        color: #fff;
      }
      &.disabled {
        opacity: 0.2;
      }
    }
  }

  .drp-selected {
    display: none;
  }

  .drp-buttons {
    display: flex !important;
    flex-direction: row-reverse;
    justify-content: flex-end;

    .applyBtn, .cancelBtn {
      border-radius: 0;
      padding: 4px 1rem;
    }

    .applyBtn {
      $green: #7edc5d;

      background-color: $bg-blue;
      text-transform: uppercase;
      color: #fff;
      border-color: $green;
      margin: 0;

      &:active {
        background-color: $green;
      }
    }

    .cancelBtn {
      background-color: #fff;
      border-color: #d1dae2;
      color: $grey;
    }
  }
}