.app-report-daily {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(7, 1fr);
    gap: 1.5rem 3rem;
    grid-template-areas:
      "pv-generation charged-battery ."
      "pv-generation pv-into-house household-consumption"
      "pv-generation pv-export-to-grid household-consumption"
      "grid-usage grid-into-house household-consumption"
      "grid-usage grid-to-batery household-consumption"
      "battery-usage battery-to-house household-consumption"
      "battery-usage battery-to-grid ."
    ;

    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: auto 1fr;
      gap: 1rem .5rem;
      grid-template-areas:
        "pv-generation pv-generation pv-generation pv-generation pv-generation pv-generation"
        ". charged-battery charged-battery charged-battery charged-battery charged-battery"
        ". pv-into-house pv-into-house pv-into-house pv-into-house pv-into-house"
        ". pv-export-to-grid pv-export-to-grid pv-export-to-grid pv-export-to-grid pv-export-to-grid"
        "grid-usage grid-usage grid-usage grid-usage grid-usage grid-usage"
        ". grid-into-house grid-into-house grid-into-house grid-into-house grid-into-house"
        ". grid-to-batery grid-to-batery grid-to-batery grid-to-batery grid-to-batery"
        "battery-usage battery-usage battery-usage battery-usage battery-usage battery-usage"
        ". battery-to-house battery-to-house battery-to-house battery-to-house battery-to-house"
        ". battery-to-grid battery-to-grid battery-to-grid battery-to-grid battery-to-grid"
        "household-consumption household-consumption household-consumption household-consumption household-consumption household-consumption"
      ;
    }
  }

  .grid-item-pv-generation {
    grid-area: pv-generation;
  }

  .grid-item-charged-battery {
    grid-area: charged-battery;
  }

  .grid-item-pv-into-house {
    grid-area: pv-into-house;
  }

  .grid-item-pv-export-to-grid {
    grid-area: pv-export-to-grid;
  }

  .grid-item-grid-usage {
    grid-area: grid-usage;
  }

  .grid-item-grid-into-house {
    grid-area: grid-into-house;
  }

  .grid-item-grid-to-batery {
    grid-area: grid-to-batery;
  }

  .grid-item-battery-usage {
    grid-area: battery-usage;
  }

  .grid-item-battery-to-house {
    grid-area: battery-to-house;
  }

  .grid-item-battery-to-grid {
    grid-area: battery-to-grid;
  }

  .grid-item-household-consumption {
    grid-area: household-consumption;
  }

  .report-block-house-consumption {
    text-align: center;
    border-color: $white;

    .report-block-value {
      justify-content: center;
    }
  }

  @include media-breakpoint-up(md) {
    .report-block-battery-charge-from-pv {
      .report-block-icon {
        font-size: 56px;
      }
    }
    .report-block-pv-consumption {
      .report-block-icon {
        font-size: 56px;
      }

      .report-block-dot {
        &.right {
          top: calc(25% - 1.6rem)
        }
      }

      &:after {
        content: '';
        position: absolute;
        width: calc(100% / 2 + 1.5rem);
        height: 1px;
        background-color: $primary;
        left: calc(100% + 1.65rem);
        top: calc(25% - 1.05rem);
      }
    }
    .report-block-grid-export {
      .report-block-icon {
        font-size: 60px;
      }
    }
    .report-block-grid-consumption {
      .report-block-icon {
        font-size: 28px;
      }
    }
    .report-block-battery-charge-from-grid {
      .report-block-icon {
        font-size: 56px;
      }
    }
    .report-block-battery-consumption {
      .report-block-icon {
        font-size: 45px;
      }

      .report-block-dot {
        &.right {
          top: auto;
          bottom: calc(25% - 1.6rem)
        }
      }

      &:after {
        content: '';
        position: absolute;
        width: calc(100% / 2 + 1.5rem);
        height: 1px;
        background-color: $primary;
        left: calc(100% + 1.65rem);
        bottom: calc(25% - 1.05rem);
      }
    }
    .report-block-battery-to-grid {
      .report-block-icon {
        font-size: 56px;
      }
    }
    .report-block-pv-generation {
      .report-block-title {
        padding-top: 7rem !important;
      }

      .report-block-icon {
        font-size: 120px !important;
        padding-top: 1rem;
      }

      &:after {
        content: '';
        position: absolute;
        height: calc(100% / 3 * 2 + 1rem);
        width: 1px;
        background-color: $primary;
        right: -1.5rem - .75rem;
        top: calc(100% / 6 - .5rem);
      }
    }
    .report-block-grid-import {
      &:after {
        content: '';
        position: absolute;
        height: calc(100% / 2 + .75rem);
        width: 1px;
        background-color: $primary;
        right: -1.5rem - .75rem;
        top: calc(100% / 4 - .375rem);
      }
    }
    .report-block-battery-usage {
      &:after {
        content: '';
        position: absolute;
        height: calc(100% / 2 + .75rem);
        width: 1px;
        background-color: $primary;
        right: -1.5rem - .75rem;
        top: calc(100% / 4 - .375rem);
      }
    }
    .report-block-house-consumption {
      $margin-y: 4rem;
      margin-top: $margin-y;
      margin-bottom: $margin-y;
      height: calc(100% - #{$margin-y * 2});

      .report-block-house-consumption-part {
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }

      .report-block-title {
        padding-top: 5rem !important;
      }

      .report-block-chart {
        flex-grow: 0 !important;
        justify-content: center;
      }

      .report-block-value {
        padding-bottom: 4rem;
      }

      .report-block-icon {
        font-size: 80px !important;
        padding-top: 1rem;

        .icon-bolt {
          font-size: 42px;
          position: relative;
          top: -.5em;
          left: -.25em;
        }
      }
    }
  }
}