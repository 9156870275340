@import "variables";

.energy-flow {
  width: 70%;
  margin: auto;
  position: relative;
  color: #78797b;
  text-align: center;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(md) {
    width: 100%;
    max-width: 450px;
  }
  @include media-breakpoint-down(sm) {
    max-width: 330px;
  }

  &_offline {
    filter: grayscale(1);

    .energy-flow__totalloads {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &__offline {
    text-align: center;
    align-self: center;
    font-size: 2.125rem;
    line-height: 1.18;
    width: 100%;
    color: $white;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    @include media-breakpoint-down(md) {
      background-color: $body-bg;
      font-size: .75rem;
      font-weight: 400;
      left: 0;
      padding: .25rem 0;
      z-index: 10;
      text-align: left;
      justify-content: flex-start;
      order: 1;
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }

  &__offline-note {
    @include media-breakpoint-up(lg) {
      font-size: 1rem;
      line-height: 1.37;
      margin-top: .5rem;
    }
  }

  &__offline-icon {
    display: inline-block;
    font-size: 1.25rem;
    margin-right: .5em;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__report {
    position: relative;
    width: 100%;
    height: 350px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 3rem;
    }

    @include media-breakpoint-down(md) {
      margin: 2rem 0 2rem;
      height: 600px;
    }
  }

  &__object {
    border-radius: 50%;
    border: 2px solid $energy-flow-object-color;
    position: absolute;
    color: $energy-flow-object-color;
    display: flex;
    align-content: center;
    justify-content: center;

    @each $breakpoint, $size in $energy-flow-object-sizes {
      @include media-breakpoint-up($breakpoint) {
        width: $size;
        height: $size;
      }
    }

    &_active {
      color: $white;
      border-color: $white;
      animation: animatedObjectPulse 2s linear infinite;

      .energy-flow__object-icon {
        animation: animatedObjectIconPulse 2s linear infinite;
      }
    }

    &_sun {
      @include media-breakpoint-up(lg) {
        top: 10%;
        left: 0;
      }

      @include media-breakpoint-down(md) {
        top: 0;
        left: calc(50% - #{map_get($energy-flow-object-sizes, xs) / 2});
      }

      .energy-flow__object-value {
        @include media-breakpoint-up(lg) {
          text-align: right;
          left: calc(#{-1 * map_get($energy-flow-object-sizes, lg)} - 1rem);
        }
        @include media-breakpoint-down(md) {
          left: 50%;
          margin-left: -1 * map_get($energy-flow-object-sizes, xs) / 2;
        }
      }
    }

    &_backuploads {
      @include media-breakpoint-up(lg) {
        bottom: 10%;
        left: 0;
      }

      @include media-breakpoint-down(md) {
        display: none;
      }

      .energy-flow__object-value {
        @include media-breakpoint-up(lg) {
          text-align: right;
          left: calc(#{-1 * map_get($energy-flow-object-sizes, lg)} - 1rem);
        }
      }
    }

    &_grid {
      @include media-breakpoint-up(lg) {
        top: 10%;
        right: 0;
      }

      @include media-breakpoint-down(md) {
        bottom: 2em;
        left: 0
      }

      .energy-flow__object-value {
        @include media-breakpoint-up(lg) {
          text-align: left;
          right: calc(#{-1 * map_get($energy-flow-object-sizes, lg)} - 1rem);
        }
        @include media-breakpoint-down(md) {
          top: auto;
          bottom: -2.75em;
        }
      }
    }

    &_home {
      @include media-breakpoint-up(lg) {
        right: 0;
        bottom: 10%;
      }

      @include media-breakpoint-down(md) {
        bottom: 2em;
        right: 0;
      }

      .energy-flow__object-value {
        @include media-breakpoint-up(lg) {
          text-align: left;
          right: calc(#{-1 * map_get($energy-flow-object-sizes, lg)} - 1rem);
          top: calc(50% - .5em);
        }
        @include media-breakpoint-down(md) {
          top: auto;
          bottom: -2.75em;
        }
      }
    }
  }

  &__object-icon {
    $font-size: 2.75rem;
    font-size: $font-size;
    //position: absolute;
    //left: calc(50% - #{$font-size / 2});
    //top: calc(50% - #{$font-size / 2});
    position: relative;
    align-self: center;
  }

  &__object-direction {
    position: absolute;
    font-size: 1.0625rem;
    line-height: 1.18;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    bottom: -2em;

    @include media-breakpoint-down(md) {
      font-size: 0.75rem;
    }
  }

  &__object-value {
    position: absolute;
    font-size: 1.5rem;
    line-height: 1.18;
    white-space: nowrap;
    width: 100%;

    @include media-breakpoint-up(lg) {
      top: calc(50% - .5em);
    }
    @include media-breakpoint-down(md) {
      top: -1.5em;
      font-weight: 300;
      font-size: 1.25rem;
    }
  }

  &__object-value-unit {
    margin-left: .25em;
    display: inline-block;

    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }
  &__battery_st {
    left: calc(50% - #{$energy-flow-battery-size-w / 2});
    top: calc(50% - #{$energy-flow-battery-size-h / 2});
    width: $energy-flow-battery-size-w;
    height: $energy-flow-battery-size-h;
    position: absolute;
    background-size: contain;
    background: url($image_path + '/energy-flow/st/battery.svg') no-repeat center center transparent;

    @include media-breakpoint-up(lg) {
    .energy-flow__battery-value {
      margin-top: -1.75rem;
    }
    .energy-flow__battery-soc {
      bottom: 9.5%;
    }
  }

    &_active {
      color: $white;

      @include media-breakpoint-down(md) {
        .energy-flow__battery-value {
          color: $primary;
        }
        .energy-flow__battery-soc {
          color: $primary;
        }
        .energy-flow__battery-soc-bar {
          border-color: $primary;
        }
        .energy-flow__battery-soc-bar-fill {
          background-color: $primary;
        }
      }

      &:after {
        @include media-breakpoint-up(lg) {
          content: '';
          background: url($image_path + '/energy-flow/st/battery-active.svg') no-repeat center center transparent;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          animation: 1s linear infinite animatedBatteryPulse;
          background-position: -43.5px -27px;
        }
      }
    }

    @include media-breakpoint-down(md) {
      left: calc(50% - #{$energy-flow-battery-mobile-size-w / 2});
      top: calc(50% - #{$energy-flow-battery-mobile-size-h / 2});
      width: $energy-flow-battery-mobile-size-w;
      height: $energy-flow-battery-mobile-size-h;
      background: url($image_path + '/energy-flow/st/battery-mobile.svg') no-repeat center transparent;
    }
  }
  &__battery_hp {
    left: calc(50% - #{$energy-flow-battery-size-w / 2});
    top: calc(50% - #{$energy-flow-battery-size-h / 2});
    width: $energy-flow-battery-size-w;
    height: $energy-flow-battery-size-h;
    position: absolute;
    background-size: contain;
    background: url($image_path + '/energy-flow/hp/battery.svg') no-repeat center center transparent;

    &_active {
      color: $white;

      @include media-breakpoint-down(md) {
        .energy-flow__battery-value {
          color: $primary;
        }
        .energy-flow__battery-soc {
          color: $primary;
        }
        .energy-flow__battery-soc-bar {
          border-color: $primary;
        }
        .energy-flow__battery-soc-bar-fill {
          background-color: $primary;
        }
      }

      &:after {
        @include media-breakpoint-up(lg) {
          content: '';
          background: url($image_path + '/energy-flow/hp/battery-active.svg') no-repeat center center transparent;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          animation: 1s linear infinite animatedBatteryPulse;
          background-position: -32px -7px;
        }
      }
    }

    @include media-breakpoint-down(md) {
      left: calc(50% - #{$energy-flow-battery-mobile-size-w / 2});
      top: calc(50% - #{$energy-flow-battery-mobile-size-h / 2});
      width: $energy-flow-battery-mobile-size-w;
      height: $energy-flow-battery-mobile-size-h;
      background: url($image_path + '/energy-flow/hp/battery-mobile.svg') no-repeat center transparent;
    }
  }

  &__battery-value {
    position: absolute;
    font-size: 1.0625rem;
    line-height: 1.18;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    top: 20%;
    font-weight: 600;

    @include media-breakpoint-down(md) {
      font-weight: 300;
      font-size: 1.25rem;
      top: calc(50% - .5em);
      right: calc(#{-1 * $energy-flow-battery-mobile-size-w});
    }
  }

  &__battery-soc {
    position: absolute;
    font-size: 1.5rem;
    line-height: 1.1;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    bottom: 20%;

    @include media-breakpoint-down(md) {
      bottom: auto;
      font-weight: 300;
      font-size: 1.25rem;
      top: calc(50% - .5em);
      left: calc(#{-1 * $energy-flow-battery-mobile-size-w} - 1em);
    }
  }

  &__battery-soc-bar {
    border: 1px solid $energy-flow-object-color;
    height: 100%;
    position: absolute;
    left: -2rem;
    width: 14px;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__battery-soc-bar-fill {
    position: absolute;
    left: 2px;
    bottom: 2px;
    background-color: $energy-flow-object-color;
    width: calc(100% - 4px);
    z-index: -1;
  }

  &__battery-direction {
    position: absolute;
    font-size: 1.0625rem;
    line-height: 1.18;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    bottom: -2em;

    @include media-breakpoint-down(md) {
      font-size: 0.75rem;
    }
  }

  &__stream {
    position: absolute;
    height: $energy-flow-stream-size;
    width: 290px;
    background: url($image_path + '/energy-flow/stream.svg') 0 0 repeat-x transparent;
    background-size: contain;

    @include media-breakpoint-down(xl) {
      width: 210px;
    }

    @include media-breakpoint-down(lg) {
      width: 150px;
    }

    @include media-breakpoint-down(md) {
      width: $energy-flow-stream-size / 1.5;
      height: 50px;
      background-repeat: repeat-y;
    }

    &_active {
      background-image: url($image_path + '/energy-flow/stream-active.svg');
      animation: animatedBackground 10s linear infinite;

      @include media-breakpoint-down(md) {
        animation: animatedMobileBackground 3s linear infinite;
      }
    }

    &_reverse {
      animation: animatedBackgroundReverse 10s linear infinite;

      @include media-breakpoint-down(md) {
        animation: animatedMobileBackgroundReverse 3s linear infinite;
      }
    }

    $marginY: calc(10% + #{map_get($energy-flow-object-sizes, lg) / 2} - #{$energy-flow-stream-size / 2});
    $marginX: calc(#{map_get($energy-flow-object-sizes, lg)} + 1em);

    &_sun {
      @include media-breakpoint-up(lg) {
        top: $marginY;
        left: $marginX;
      }

      @include media-breakpoint-down(md) {
        top: calc(#{map_get($energy-flow-object-sizes, xs)} + 1.5rem);
        left: calc(50% - #{$energy-flow-stream-size / 1.5 / 2});
      }
    }

    &_backuploads {
      @include media-breakpoint-up(lg) {
        left: $marginX;
        bottom: $marginY;
      }

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &_grid {
      @include media-breakpoint-up(lg) {
        top: $marginY;
        right: $marginX;
      }

      @include media-breakpoint-down(md) {
        bottom: calc(#{map_get($energy-flow-object-sizes, xs)} + 1.5rem);
        transform: rotateZ(43deg);
        left: 30%;
      }
    }

    &_home {
      @include media-breakpoint-up(lg) {
        right: $marginX;
        bottom: $marginY;
      }

      @include media-breakpoint-down(md) {
        bottom: calc(#{map_get($energy-flow-object-sizes, xs)} + 1.5rem);
        transform: rotateZ(-43deg);
        right: 30%;
      }
    }
  }

  &__showLoadsSmall
  {



    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__showLoadsLarge
  {




    @include media-breakpoint-up(md) {
      display: none;
    }


  }


  &__totalloads {
    font-size: 1rem;
    line-height: 1.2;
    padding: 1rem 0;
    color: $white;
    border-top: 1px solid rgba($white, .2);
    border-bottom: 1px solid rgba($white, .2);

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__gridsideloads {
    font-size: 0.75rem;
    position: relative;
    align-self: center;
    text-align: right;
    color: $energy-flow-object-color;
  }

  &__backuploads {
    font-size: 0.75rem;
    position: relative;
    align-self: center;
    text-align: left;
    color: $energy-flow-object-color;
  }

}

@keyframes animatedBackground {
  from {background-position: 0 0;}
  to {background-position: 100% 0;}
}
@keyframes animatedBackgroundReverse {
  from {background-position: 0 0;}
  to {background-position: -100% 0;}
}
@keyframes animatedMobileBackground {
  from {background-position: 0 0;}
  to {background-position: 0 100%;}
}
@keyframes animatedMobileBackgroundReverse {
  from {background-position: 0 0;}
  to {background-position: 0 -100%;}
}
@keyframes animatedObjectPulse {
  from {border-color: $energy-flow-object-pulse-color-start;}
  35% {border-color: $energy-flow-object-pulse-color-end;}
  65% {border-color: $energy-flow-object-pulse-color-end;}
  to {border-color: $energy-flow-object-pulse-color-start;}
}
@keyframes animatedObjectIconPulse {
  from {color: $energy-flow-object-pulse-color-start;}
  35% {color: $energy-flow-object-pulse-color-end;}
  65% {color: $energy-flow-object-pulse-color-end;}
  to {color: $energy-flow-object-pulse-color-start;}
}
@keyframes animatedBatteryPulse {
  from {opacity: 1;}
  50% {opacity: 0;}
  to {opacity: 1;}
}