@import "variables";

.dateslineselect {
  &__container {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      @each $modifier, $count in $dateslineselect-block-count {
        &_#{$modifier} {
          width: $dateslineselect-block-sm-width * $count + $dateslineselect-block-margin-y * ($count - 1);
        }
      }
    }
  }

  &__block {
    border: 1px solid rgba(255, 255, 255, 0.2);
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    padding: 1.75rem 1rem;

    @each $modifier, $width in $dateslineselect-block-width {
      &_#{$modifier} {
        width: $width;
      }
    }

    @include media-breakpoint-down(md) {
      width: $dateslineselect-block-sm-width;
    }

    &_date {
      font-weight: 300;
      padding: 0;
    }

    &_year {
      font-weight: 300;
      margin-right: .5rem;
      flex-grow: 1;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &:hover {
      border-color: $white;
    }

    &_active {
      border: 0 none;
      background: $primary;
      cursor: default;
    }

    &_disabled {
      cursor: not-allowed;
      opacity: .5;
      border-color: rgba($white, .4);

      &:hover {
        border-color: rgba($white, 1);
      }
    }

    &_datepicker {
      color: $primary;
      border-color: $primary;
      padding: 0;

      @include media-breakpoint-down(lg) {
        padding: 0 .5rem;
      }

      .react-datepicker-wrapper,
      .react-datepicker__input-container,
      .dateslineselect__value {
        width: 100%;
        height: 100%;
      }

      .dateslineselect__value {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &_static {
      cursor: default;

      &:hover {
        border-color: inherit;
      }
    }

    & + .dateslineselect__block {
      margin-left: $dateslineselect-block-margin-y;
    }
  }

  .dropdown + .dateslineselect__block {
    margin-left: $dateslineselect-block-margin-y;
  }

  &__value {
    &_week {
      font-size: 1.125rem;
      line-height: 2.22em;
      letter-spacing: 0.03em;

      @include media-breakpoint-down(sm) {
        font-size: 1rem;
      }
    }

    &_day {
      font-weight: 600;
      font-size: 2.05rem;
      line-height: .825em;
      letter-spacing: 0.015em;

      @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
      }
    }

    &_month {
      font-size: 0.8125rem;
      line-height: 3.08em;
      letter-spacing: 0.037em;

      @include media-breakpoint-down(sm) {
        line-height: 2.22em;
      }
    }
  }

  &__arrow-down {
    width: 1rem;
    height: 1rem;
    background: str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='none' stroke-linejoin='miter' stroke-linecap='butt' stroke-miterlimit='4' stroke-width='7.1111' stroke='#{$primary}' d='M2.889 9.949l13.111 12.103 13.111-12.103'/%3e%3c/svg%3e"), "#", "%23") no-repeat center center;
    display: inline-block;
    margin-left: .5rem;
  }

  &__dropdown-menu {
    background-color: transparent;
    padding: 0;
    max-width: 320px;

    @include media-breakpoint-down(md) {
      top: 70px !important;
    }
  }
}