@mixin font-face($name, $file, $weight: null, $style: null, $exts: eot woff ttf svg, $local: null) {
  $path: $font_path + '/' + $file;

  $extmods: (
    svg: '#' + str-replace($name, ' ', '_')
  );

  $formats: (
    otf: 'opentype',
    ttf: 'truetype'
  );

  $font-files: null;

  @if $local {
    @if type-of($local) == list {
      @each $local-row in $local {
        $font-files: append($font-files, local('#{$local-row}'), comma);
      }
    } @else {
      $font-files: append($font-files, local('#{$local}'), comma);
    }
  }

  @font-face {
    font-family: quote($name);

    @each $ext in $exts {
      $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
      $file: $path + '.' + $extmod;
      @if $ext == eot {
        $iefont: unquote('#{$file}?#iefix');
        src: url(#{$file}?);
        $font-files: append($font-files, url($iefont) unquote('format("embedded-opentype")'), comma);
      } @else if $ext == otf {
        $font-files: append($font-files, url($file) unquote('format("opentype")'), comma);
      } @else {
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $font-files: append($font-files, url($file) unquote('format("#{$format}")'), comma);
      }
    }
    src: $font-files;
    @if $weight {
      font-weight: $weight;
    } @else {
      font-weight: 400;
    }
    @if $style {
      font-style: $style;
    } @else {
      font-style: normal;
    }
  }
}