.device-link-page {
    &__modal {
        @include media-breakpoint-up(xl) {
            padding-right: 250px;
            position: relative;
        }

        &:after {
            @include media-breakpoint-up(xl) {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 250px;
                height: 100%;
                border-left: 1px solid $white;
                background: url($image_path + '/devices.svg') no-repeat center center transparent;
                background-size: 299px 270px;
            }
        }

        .close {
            @include media-breakpoint-up(xl) {
                right: -250px;
                z-index: 1000;
            }
        }
    }

    &__form-group {
        position: relative;
    }

    &__form-control {
        text-align: center;
    }

    &__exclamation {
        position: absolute;
        right: .5rem;
        top: calc(50% - 1.25rem / 2);
        background: $primary;
        width: 1.25rem;
        height: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .75rem;
        cursor: pointer;
    }

    &__remind-verification-code-btn {
        font-size: .75rem;
        margin: auto;
    }

    &__remind-title {
        font-size: 1.5rem;
        font-weight: 300;
    }

    &__icon {
        font-size: 5.175rem;
        color: $primary;
        margin-top: 1rem;
        margin-bottom: 3.25rem;

        &_email {
            font-size: 5.75rem;
        }

        &_emoji-sad {
            color: $red;
            margin-top: 5.75rem;
            margin-bottom: 5.75rem;
        }

        &_emoji-smile {
            margin-top: 5.75rem;
            margin-bottom: 5.75rem;
        }
    }
}