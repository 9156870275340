.creatable-select {
  &__control {
    width: 100%;
    height: $input-height;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color !important;;
    background-color: $input-bg !important;;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color !important;
    border-radius: $input-border-radius !important;;
  }

  &__single-value {
    color: $input-color !important;
  }

  &__value-container {
    input {
      color: $input-color !important;
      font-weight: 600;
      opacity: 1 !important;
      height: 100%;
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__indicator {
    color: $primary !important;
  }

  &__menu {
    background-color: $body-bg !important;
    border: 1px solid $white !important;
    border-radius: $border-radius !important;
    z-index: 200;
  }

  &__menu-list {
    background-color: $body-bg !important;
  }

  &__option {
    background-color: $body-bg !important;

    &--is-focused {
      background-color: rgba($primary, .5) !important;
    }

    &:hover {
      background-color: rgba($primary, .5) !important;
    }
  }
}