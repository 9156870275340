@import '~toastr/toastr';

#toast-container > div {
  background-size: 1.25rem 1.25rem;
  background-position: 1.75rem 1.75rem;
  padding: 1.75rem 1.75rem 1.75rem 4rem;
  border-radius: 0;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  box-shadow: none;
}

.toast {
  border: 1px solid #616161;
  background-color: $black;
}
.toast-success {
  border: 1px solid #88D850;
  background-color: $black;
}
.toast-error {
  border: 1px solid #FC1E1E;
  background-color: $black;
}
.toast-info {
  border: 1px solid $primary;
  background-color: $black;
}
.toast-warning {
  border: 1px solid #FE9B64;
  background-color: $black;
}

#toast-container > .toast-info {
  background-image: str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='#{$primary}' d='M64 32c0-17.673-14.327-32-32-32s-32 14.327-32 32c0 17.673 14.327 32 32 32s32-14.327 32-32zM5.818 32c0-14.46 11.722-26.182 26.182-26.182s26.182 11.722 26.182 26.182c0 14.46-11.722 26.182-26.182 26.182s-26.182-11.722-26.182-26.182zM34.909 17.454v17.454h-5.818v-17.454h5.818zM34.909 43.636v-5.818h-5.818v5.818h5.818z'/%3e%3c/svg%3e"), "#", "%23") !important;
}
#toast-container > .toast-error {
  background-image: str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='#FC1E1E' d='M64 32c0-17.673-14.327-32-32-32s-32 14.327-32 32c0 17.673 14.327 32 32 32s32-14.327 32-32zM5.818 32c0-14.46 11.722-26.182 26.182-26.182s26.182 11.722 26.182 26.182c0 14.46-11.722 26.182-26.182 26.182s-26.182-11.722-26.182-26.182zM34.909 17.454v17.454h-5.818v-17.454h5.818zM34.909 43.636v-5.818h-5.818v5.818h5.818z'/%3e%3c/svg%3e"), "#", "%23") !important;
}
#toast-container > .toast-success {
  background-image: str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='#88D850' d='M64 32c0-17.673-14.327-32-32-32s-32 14.327-32 32c0 17.673 14.327 32 32 32s32-14.327 32-32zM5.818 32c0-14.46 11.722-26.182 26.182-26.182s26.182 11.722 26.182 26.182c0 14.46-11.722 26.182-26.182 26.182s-26.182-11.722-26.182-26.182zM34.909 17.454v17.454h-5.818v-17.454h5.818zM34.909 43.636v-5.818h-5.818v5.818h5.818z'/%3e%3c/svg%3e"), "#", "%23") !important;
}
#toast-container > .toast-warning {
  background-image: str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='#FE9B64' d='M64 32c0-17.673-14.327-32-32-32s-32 14.327-32 32c0 17.673 14.327 32 32 32s32-14.327 32-32zM5.818 32c0-14.46 11.722-26.182 26.182-26.182s26.182 11.722 26.182 26.182c0 14.46-11.722 26.182-26.182 26.182s-26.182-11.722-26.182-26.182zM34.909 17.454v17.454h-5.818v-17.454h5.818zM34.909 43.636v-5.818h-5.818v5.818h5.818z'/%3e%3c/svg%3e"), "#", "%23") !important;
}

.toast-title {
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.37;
}

.toast-message {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.37;
}

.toast-title + .toast-message {
  margin-top: 1rem;
}