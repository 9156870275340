.nav-tabs {
  .nav-link {
    text-transform: uppercase;
    font-size: .75rem;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      color: $primary;
    }
  }
}