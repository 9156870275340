// deprecated
.report {
  $block-spacing-y: 1.875rem;
  $block-lg-spacing-y: 3rem 1.875rem;

  .report-block {
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid $primary;
    padding: $block-spacing-y;
    height: 100%;

    @include media-breakpoint-down(sm) {
      padding: 1rem;
    }

    &.report-block-lg {
      padding: $block-lg-spacing-y;

      @include media-breakpoint-down(sm) {
        padding: 1rem;
      }

      .report-block-title {
        font-weight: 300;
        font-size: 2.125rem;
        padding-top: 2rem;

        @include media-breakpoint-down(sm) {
          font-size: 1.75rem;
          padding-top: 1rem;
        }
      }

      .report-block-icon {
        font-size: 66px;
        position: relative;
        right: auto;
        top: auto;

        @include media-breakpoint-down(sm) {
          font-size: 2rem;
        }
      }

      .report-block-description {
        margin-top: 2rem;

        @include media-breakpoint-down(sm) {
          margin-top: 1rem;
        }
      }

      .report-block-chart {
        position: relative;
        right: auto;
        bottom: auto;
        flex-grow: 1;
        display: flex;
        align-items: flex-end;
      }

      .report-block-value {
        font-size: 4.625rem;
        padding-top: 2rem;
        flex-grow: 0;

        @include media-breakpoint-down(sm) {
          font-size: 2rem;
        }
      }
    }
  }

  .report-block-title {
    font-size: 1.5rem;
    line-height: 1.17;
    letter-spacing: 0.02em;

    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
    }
  }

  .report-block-description {
    font-size: 0.6785rem;
    line-height: 1.62;
    letter-spacing: 0.03em;
    color: rgba($white, .5);
    margin-top: 4rem;
    margin-bottom: 1.5rem;
    height: 3rem;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      margin-right: 3.5rem;
      height: auto;
      overflow: inherit;
    }
  }

  .report-block-value {
    font-weight: 600;
    font-size: 2.75rem;
    letter-spacing: 0.01em;
    color: $primary;
    margin-bottom: -.25em;
    flex-grow: 1;
    display: flex;
    align-items: baseline;

    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }
  }

  .report-block-value-unit {
    font-weight: 300;
    font-size: 1.375rem;
    letter-spacing: 0.02em;
    color: $primary;
    display: inline-block;
    margin-left: .75rem;

    @include media-breakpoint-down(sm) {
      font-size: .875rem;
    }
  }

  .report-block-icon {
    font-size: 60px;
    position: absolute;
    right: $block-spacing-y;
    top: $block-spacing-y;

    @include media-breakpoint-down(sm) {
      font-size: 2rem;
      right: 1rem;
      top: 1rem;
    }
  }

  .report-block-chart {
    position: absolute;
    right: $block-spacing-y;
    bottom: $block-spacing-y;

    @include media-breakpoint-down(sm) {
      right: 1rem;
      bottom: 1rem;
    }
  }

  .report-block-dot {
    $width: 0.875rem;
    position: absolute;
    width: $width;
    height: $width;
    border-radius: $width;
    background-color: $primary;
    border: 2px solid $primary;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &.xl {
      border: 6px solid $primary;
      background-color: $black;
    }

    &:after {
      content: '';
      background-color: $primary;
      position: absolute;
      z-index: -1;
    }

    &.top {
      left: calc(50% - #{$width / 2});
      top: $width / 2 * -1;

      &:after {
        height: 0.75rem;
        width: 1px;
        bottom: $width / 2;
        left: calc(50% - .5px);
      }
    }

    &.bottom {
      left: calc(50% - #{$width / 2});
      bottom: $width / 2 * -1;

      &:after {
        height: 0.75rem;
        width: 1px;
        top: $width / 2;
        left: calc(50% - .5px);
      }
    }

    &.left {
      top: calc(50% - #{$width / 2});
      left: $width / 2 * -1;

      &:after {
        width: 0.75rem;
        height: 1px;
        right: $width / 2;
        top: calc(50% - .5px);
      }
    }

    &.right {
      top: calc(50% - #{$width / 2});
      right: $width / 2 * -1;

      &:after {
        width: 2.25rem;
        height: 1px;
        left: $width / 2;
        top: calc(50% - .5px);
      }
    }

    $sizes: (
      'lg': 1.168rem,
      'xl': 2.125rem
    );

    @each $size, $width in $sizes {
      &.#{$size} {
        width: $width;
        height: $width;
        border-radius: $width;

        &.top {
          left: calc(50% - #{$width / 2});
          top: $width / 2 * -1;
          &:after {
            bottom: $width / 2;
          }
        }
        &.bottom {
          left: calc(50% - #{$width / 2});
          bottom: $width / 2 * -1;
          &:after {
            top: $width / 2;
          }
        }
        &.left {
          top: calc(50% - #{$width / 2});
          left: $width / 2 * -1;
          &:after {
            right: $width / 2;
          }
        }

        &.right {
          top: calc(50% - #{$width / 2});
          right: $width / 2 * -1;
          &:after {
            left: 50%;
          }
        }
      }
    }
  }
}