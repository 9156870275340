.carousel {
  @include media-breakpoint-down(md) {
    margin: 0 $grid-gutter-width / 2 * -1;
    padding: 0 $grid-gutter-width / 2;
    overflow-x: auto;
  }

  .carousel-container {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      padding-right: $grid-gutter-width / 2;
    }
  }
}