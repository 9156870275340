.page-settings {
  &__card {
    @include media-breakpoint-up(lg) {
      background-color: $card-bg;
      background-clip: border-box;
      border: $card-border-width solid $card-border-color;
      padding: 2.5rem;
      color: $card-color;
    }
  }

  &__card-title {
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 1.37;
    letter-spacing: 0.02em;
    margin-bottom: 3rem;
  }

  &__group-title {
    color: $primary;
    font-size: 1.5rem;
    line-height: 1.37;
    font-weight: 300;
    margin: 3rem 0 1rem;

    &_white {
      color: $white;
    }
  }

  &__note {
    font-size: 0.6875rem;
  }

  &__form-group {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid rgba($white, .2);
    letter-spacing: .02em;
  }

  &__creatable-select {
    margin-left: 4rem;

    @include media-breakpoint-down(sm) {
      margin-left: 0;
    }
  }

  &__input-prepend {
    width: 4rem;
    border-width: 0;
    display: flex;
    justify-content: flex-end;
    font-weight: 600;

    @include media-breakpoint-down(sm) {
      justify-content: center;
      padding: 0;
    }
  }

  &__form-control-suffix {
    font-weight: 300;
    font-size: 0.75rem;
    position: absolute;
    left: 3rem;
    top: 50%;
    margin-top: -1 * 0.75rem / 2;

    &_energy-rate {
      left: 8rem;
    }
  }

  &__form-control {
    background-color: transparent;
    font-weight: 600;

    @include media-breakpoint-only(md) {
      padding: $input-padding-y $input-padding-x / 2;
      font-weight: 400;
    }

    &[type=number] {
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    &_clock {
      padding: 0;
      .rc-time-picker-input {
        @include media-breakpoint-only(md) {
          padding: $input-padding-y $input-padding-x / 2;
          font-weight: 400;
        }
      }
    }
  }

  &__form-control-buttons {
    position: absolute;
    top: $input-border-width;
    right: $input-border-width;
    height: $input-height;
    //z-index: 100;
  }

  &__form-control-button {
    height: calc(#{$input-line-height * 1rem} + #{$input-padding-y * 2});
    background-color: $input-bg;
    width: calc(#{$input-line-height * 1rem} + #{$input-padding-y * 2});
    border: 0 none;
    font-size: 1.375rem;
    color: $primary;
    padding: 0;

    &[disabled] {
      opacity: .5;
    }

    &_clock {
      @include media-breakpoint-only(md) {
        display: none;
      }
    }
  }

  &__slider-wrapper {
    margin-top: 3.5rem;

    @include media-breakpoint-down(md) {
      margin-top: 3rem;
    }
  }

  &__btn-save {
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
      display: block;
      width: 100%;
    }
  }

  &__save-success {
    margin-left: 2rem;

    @include media-breakpoint-down(sm) {
      margin: 1rem 0 0;
      display: block;
    }
  }
}