.page-monthly-report {
  .report-block {
    &__icon {
      &_household-consumption {
        @include media-breakpoint-only(md) {
          margin: 0 1rem;
          font-size: 3rem;
        }

        @include media-breakpoint-down(sm) {
          font-size: 2rem;
        }
      }
    }

    &__energy {
      &_household-consumption {
        @include media-breakpoint-down(sm) {
          flex-direction: row !important;
        }
      }
    }
    
    &_household-consumption {
      justify-content: center;
      flex-direction: row !important;

      @include media-breakpoint-up(md) {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }

      & > div:nth-child(2) {
        flex: initial;
        align-items: baseline;
        margin-left: 1.5rem;

        @include media-breakpoint-up(md) {
          margin-left: 2.5rem;
        }
        @include media-breakpoint-up(lg) {
          margin-left: 2rem;
        }
      }
    }
  }
}