.alert {
  font-size: $font-size-sm;
  font-weight: 300;
  padding-left: 2rem;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -.75rem;
    color: $white;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2 11'%3e%3cpath fill='white' d='M0 11L2 11L2 9L1.74846e-07 9L0 11ZM2.62268e-07 8L2 8L2 0L9.61651e-07 -1.74846e-07L2.62268e-07 8Z'/%3e%3c/svg%3e");
    width: 1.25rem;
    height: 1.25rem;
    background-size: 2px 11px;
  }
}

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @include alert-variant($black, transparent, theme-color($color));

    &:before {
      background-color: theme-color($color);
    }
  }
}
