.page-reboot {
  &__title {
    font-weight: 300;
    text-align: center;
    font-size: 1.5rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.25rem;
    }
  }

  &__description {
    text-align: center;
    color: $orange;
    margin-top: 1.25rem;
    height: 2.5rem;
  }

  &__image {
    background: url($image_path + '/device-reboot.svg') center center transparent no-repeat;
    height: 270px;
    margin-top: 2rem;
    background-size: contain;

    @include media-breakpoint-down(sm) {
      height: 170px;
    }

    &_progress {
      background-image: url($image_path + '/device.svg');
    }
  }

  &__progress-value {
    font-size: 1.5rem;
    color: $primary;
    font-weight: 600;
    text-align: center;
    margin: 3rem 0 2rem;

    @include media-breakpoint-only(lg) {
      margin: 1.5rem 0;
    }
  }

  &__btn {
    @include media-breakpoint-up(md) {
      margin-top: 0 !important;
    }

    &_cancel {
      border-color: $primary;
      color: $primary;

      @include media-breakpoint-up(md) {
        margin-right: 1.5rem;
      }
    }
  }
}