@import "variables";

.report-summary-block {
    border-width: 3px;
    border-style: solid;
    margin-bottom: $grid-gutter-width;
    position: relative;
    padding: $report-summary-block-spacing-y;
    display: flex;
    flex-direction: column;

    &:after {
        content: '';
        position: absolute;
        right: $report-summary-block-spacing-y;
        bottom: $report-summary-block-spacing-y;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        border-width: calc(1.25rem / 2);
        border-style: solid;
    }

    &__icon {
        font-size: 1.625rem;
        position: absolute;
        color: $white;
        left: $report-summary-block-spacing-y;
        top: $report-summary-block-spacing-y + .5rem;

        @include media-breakpoint-down(sm) {
            font-size: 1rem;
        }
    }

    &__title {
        color: $white;
        font-size: 1rem;
        line-height: 1.44;
        letter-spacing: 0.03em;
        flex-grow: 1;
        margin-left: $report-summary-block-spacing-y + 1.625rem;
        margin-bottom: 1rem;

        @include media-breakpoint-down(xl) {
            font-size: 0.9rem;
        }

        @include media-breakpoint-down(lg) {
            font-size: 0.7rem;
        }

        @include media-breakpoint-down(sm) {
            font-size: 0.9rem;
        }
    }

    &__energy {
        font-weight: 600;
        font-size: 2.06rem;
        letter-spacing: 0.015em;
        margin-bottom: -.25em;
        display: flex;
        align-items: baseline;

        @include media-breakpoint-down(sm) {
            font-size: 1.5rem;
        }
    }

    &__energy-unit {
        font-weight: 300;
        font-size: 0.8125rem;
        letter-spacing: 0.03em;
        display: inline-block;
        margin-left: .75rem;

        @include media-breakpoint-down(sm) {
            font-size: .875rem;
        }
    }
}