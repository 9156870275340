.page-report-heatmap {

  &__report-type-link {
    @include media-breakpoint-up(lg) {
      font-size: 1.3125rem !important;
      font-weight: 300 !important;
      text-transform: none !important;
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
  }

  &__datetypeselect-container {
    display: flex;
    justify-content: space-between;
  }

  &__datetypeselect-block {
    flex-grow: 1;
    border: 1px solid rgba($white, .2);
    font-size: .8725rem;
    text-align: center;
    padding: 1rem;
    cursor: pointer;

    &_active {
      border-color: $primary;
      color: $primary;
    }
  }
}