.logo {
  padding: 2rem 0;

  @include media-breakpoint-down(sm) {
    padding: 1rem 0;
  }

  .hyperian {
    background: url($image_path + '/hyperian.svg') center center transparent no-repeat;
    width: 260.8px;
    height: 65.83px;
    margin: auto;
  }

  .homepower {
    background: url($image_path + '/homepower.svg') center center transparent no-repeat;
    width: 192.6px;
    height: 17.61px;
    margin: 1.7rem auto 0;
  }

  &.logo-short {
    padding: 0;

    .hyperian {
      width: 105px;
      height: 25.48px;
      background-size: contain;
    }
  }
}