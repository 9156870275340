.modal {
  .modal-dialog {
    @include media-breakpoint-down(md) {
      max-width: none;
      margin: 0;
      height: 100vh;
    }
  }

  .modal-content {
    @include media-breakpoint-down(md) {
      height: 100vh;
      overflow: scroll;
    }
  }

  .close {
    color: $white;
    text-shadow: none;
    position: relative;
    opacity: 1;

    span {
      visibility: hidden;
    }

    $width: 20px;

    &:before {
      content: '';
      position: absolute;
      left: calc(50% - #{$width / 2});
      top: 50%;
      width: $width;
      height: 2px;
      background-color: $white;
      transform: rotateZ(-45deg);
    }
    &:after {
      content: '';
      position: absolute;
      left: calc(50% - #{$width / 2});
      top: 50%;
      width: $width;
      height: 2px;
      background-color: $white;
      transform: rotateZ(45deg);
    }
  }

  .modal-title {
    width: 100%;
    text-align: center;
    font-weight: 300;
    font-size: 2.125rem;
    letter-spacing: 0.014em;
    padding-top: 1rem;
  }
}